.data-area-container {
  padding: 20px 10px;

  .data-item-container {
    background: #444;
    margin: 40px 0;
    position: relative;
    padding: 10px 30px;

    .action-container {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;

      .add-section-btn {
        cursor: pointer;
        opacity: 0;
      }
  
      &:hover {
        .add-section-btn {
          opacity: 1;
        }
      }
    }

    &:hover {
      .add-section-btn {
        opacity: 1;
      }
    }

    .action-container-main {
      position: absolute;
      top: 48px;
      left: 30px;
      display: none;
      
      .action-button {
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #aaa;
          margin-right: 5px;
          cursor: pointer;
          border-radius: 5px;
      }
    }

    &:hover {
      .action-container-main {
        display: block;
      }
    }
  }

  .normal-container {
    padding: 10px 30px 30px 30px;
  }

  .action-container {
    cursor: pointer;
  }
}

.theme-dark {
  .add-section-btn {
    color: #fff;
  }
}

.theme-light {
  .add-section-btn {
    color: #000;
  }
}


