.size-picker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .size-picker-item {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin: 4px 5px;
    cursor: pointer;
    border: 2px solid #cccccc;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
