.popup-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;

  .chrome-picker,
  .colorPickerTitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .colorPickerTitle {
    font-size: 14px;
    font-weight: bold;
  }

  .main-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #ffffff;
    position: fixed;
    width: auto;

    border-radius: 5px;
    padding: 4px;
    box-shadow: 0 4px 20px 10px rgba(0, 0, 0, 0.2);

    .popup-title-container {
      padding: 10px;
      background: #cccccc;
      margin: -4px;
      position: relative;

      .popup-title {
        font-size: 16px;
        color: #666666;
        margin-bottom: 5px;
      }

      .popup-header-action {
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          font-size: 20px;

          &.icon-close {
            color: #e48f8f;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .popup-title-input {
      width: 100%;
      margin-top: 5px;
      padding: 9px;
      border: 1px solid #cccccc;
      border-radius: 3px;

      &:focus {
        border: 1px solid #cccccc;
      }
    }

    .popup-arrow {
      height: 10px;
      width: 10px;
      position: absolute;
      background: rgb(255, 255, 255);
      transform: rotate(45deg);
      margin: -5px;
      z-index: -1;
      box-shadow: rgba(0, 0, 0, 0.2) -1px 1px 1px;
      top: 50%;
      left: 0%;
    }

    .footer-container {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #d4d4d4;
      padding: 10px 4px;

      span {
        font-size: 20px;

        &.icon-edit {
          color: #8ac7e4;
        }

        &.icon-save {
          color: #8ac7e4;
        }

        &.icon-remove {
          color: #e48f8f;
        }
      }
    }
  }
}