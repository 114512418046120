.edit-design-card-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-back {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #999999;
    opacity: 0.6;
  }

  .edit-design-container {
    padding: 10px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 1px 1px 17px 1px black;

    .image-wrapper {
      width: 100%;
      border-radius: 5px;
    }

    .title-wrapper {
      display: flex;
      justify-content: flex-start;
      .title-container {
        padding: 5px 10px;
        border: none;
        font-size: 20px;
        outline: none;
        background: transparent;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: flex-start;
      .content-container {
        padding: 5px 10px;
        border: none;
        font-size: 16px;
        outline: none;
        width: 100%;
        min-height: 150px;
        background: transparent;
      }
    }

    .toolbar-container {
      display: flex;
      justify-content: space-between;

      .btn-group {
        display: flex;

        .color-action {
          position: relative;

          &:hover {
            .color-picker-wrapper {
              display: block;
            }
          }

          .color-picker-wrapper {
            display: none;
            position: absolute;
            width: 176px;
            top: -95px;
            background: #fff;
            border-radius: 10px;

            &:hover {
              display: block;
            }
          }
        }

        .image-ation {
          position: relative;

          .image-uploader {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100%;
            cursor: pointer;
          }
        }
      }

      span {
        font-size: 20px;
        color: #aaaaaa;
        cursor: pointer;
        padding: 5px;
        margin: 0 10px;

        &:first-child {
          margin: 0 10px 0 0;
        }

        &:hover {
          color: #333333;
        }
      }

      .close-btn-wrapper {
        span {
          margin-right: 0;
        }
      }
    }
  }
}