@import '~antd/dist/antd.css';

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Sanspro";
  src: local("Sanspro"), url("./assets/fonts/source-sans-pro.regular.ttf") format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ag-header-cell-label {
  justify-content: center;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.flexlayout__layout {
  margin-top: 60px;
}

.flexlayout__border_button {
  color: #000 !important;
  background: #fff !important;
}

.content-wrapper button {
  height: 1.9rem !important;
  line-height: 1.9rem !important;
  padding: 0 !important;
  margin: 0 !important;
}

.content-wrapper button span {
  height: 100% !important;
}

.theme-dark .content-wrapper button span {
  color: #000;
}

.content-wrapper button[disabled] {
  background-color: #fff !important;
  opacity: 0.5 !important;
}

.theme-dark .content-wrapper input[type=text],
.theme-dark .content-wrapper label span {
  color: #000 !important;
}

/*.flexlayout__tabset_tabbar_outer {*/
/*display: none !important;*/
/*height: 0 !important;*/
/*}*/

/*.flexlayout__tab {*/
/*top: 0 !important;*/
/*}*/