.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.theme-light .w-md-editor{
    background: #fff;
    color:#000;
}

button[data-name='hr']{
    display:none;
}

button[data-name='title2']{
    display:none;
}

button[data-name='strikethrough']{
    display:none;
}

button[data-name='quote']{
    display:none;
}

.content-container {
    padding: 10px 30px 10px 30px;
    margin-bottom:30px;

    .title-wrapper {
        position: relative;
        display: flex;

        span{
            color:#fff;
            opacity:0.6;
        }

        input {
            width: 100%;
            background: transparent;
            color: #f0992a;
            border: none;
            outline: none;
            margin-left:5px;
        }

        .action-container-right {
            position: absolute;
            top: 0px;
            right: -40px;
            display: none;
            
            .action-button {
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                cursor: pointer;
                border-radius: 5px;
                
                span {
                    color: #ff0000;
                }
            }
        }
    }

    .content-wrapper {
        position: relative;

        textarea {
            width: 100%;
            background: transparent;
            color: #fff;
            border: none;
            min-height: 38px;
            resize: none;
            outline: none;
        }

        .action-container-left {
            position: absolute;
            bottom: 0px;
            left: -30px;
            display: none;
            
            .action-button {
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #aaa;
                margin-right: 10px;
                cursor: pointer;
                border-radius: 5px;
            }
        }
    }

    &:hover {
        .action-container-left {
            display: block;
        }
        
        .action-container-right {
            display: block;
        }
    }
}