$small: 768px;

.design-panel {
  display: flex;
  overflow: auto;
  justify-content: center;
  padding: 20px 30px;
  height: 100%;
  flex-direction: column;

  @media screen and (max-width: $small) {
    padding: 10px 10px;
  }

  .title-container {
    position: relative;
    margin-bottom: 10px;
    .panel-title {
      font-size: 16px;
      color: #000;
      font-family: 'Inter', serif;
      font-weight: 700;
    }

    .panel-sub-title {
      position: absolute;
      top: 0;
      left: 0;
    }

    @media screen and (max-width: $small) {
      padding: 10px;

      .panel-title{
        width:100% !important;
      }
    }
  }

  .muuri {
    width: 100%;
    border-radius: 10px;
    min-height: 150px;
    height: 100% !important;
  }

  .context-menu-container {
    position: fixed;
    list-style: none;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 9px 1px #717171;
    background: #fff;
    z-index: 100;

    li {
      cursor: pointer;
      padding: 5px 10px;
      color: #3c3b3b;
      border-radius: 4px;

      &:hover {
        background: #1890ff;
        color: #fff;
      }
    }
  }

  .add-design-card-container {
    position: absolute;
    bottom: 40px;
    right: 30px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .update-view-design-card-container {
    position: absolute;
    bottom: 100px;
    right: 30px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .filter-design-btn-card-container {
    position: absolute;
    top: 15px;
    right: 30px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
