@import '../../theme';

.theme-light {
  .flexlayout__tab {
    background-color: $theme-light-background;
  }

  .flexlayout__border {
    border-color: $theme-light-border;
  }

  .flexlayout__splitter {
    background: $theme-light-border;
  }
}

.theme-dark {
  .flexlayout__tab {
    background-color: $theme-dark-background;
  }

  .flexlayout__border {
    border-color: $theme-dark-border;
  }

  .flexlayout__splitter {
    background: $theme-dark-border;
  }
}
