.register-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .register-page-container {
    box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 30px;

    .register-form {
      display: flex;
      flex-direction: column;

      input {
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #cecece;
        border-radius: 5px;
        outline: none;
      }
    }

    .btn-group-container {
      display: flex;
      flex-direction: column;

      .btn-signup {
        margin: 5px 0;
        border-radius: 42px;
        border: 1px solid gray;
        background: #0000ff;
        padding: 10px;
        color: #fff;
        cursor: pointer;
      }

      .btn-signin {
        margin: 5px 0;
        border-radius: 42px;
        border: 1px solid gray;
        background: #0000ff;
        padding: 10px;
        color: #fff;
        cursor: pointer;
      }
    }
  }

}