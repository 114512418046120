.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.title-container {
  position: relative;

  
  display:flex;
  align-items:center;

  span{
    color:#fff;
    opacity:0.6;
    font-weight:bold;
  }

  .header-title {
    width: 100%;
    background: transparent;
    color: #fff;
    font-size: 26px;
    border: none;
    outline: none;
  }

  .action-container-left {
    position: absolute;
    top: 8px;
    left: -26px;
    display: none;

    .action-button {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #aaa;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .action-container-right {
    position: absolute;
    top: 10px;
    right: -35px;
    display: none;

    .action-button {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 5px;

      span {
        color: #ff0000;
      }
    }
  }

  &:hover {
    .action-container-left {
      display: block;
    }

    .action-container-right {
      display: block;
    }
  }
}
