$theme-light-background: #fff;
$theme-light-content: #000;
$theme-light-border: #f7f7f7;
$theme-light-pane1-background: #FFFFF6;
$theme-light-pane2-background: #FFFFF6;
$theme-light-header-background: #fff;

$theme-dark-background: #434343;
$theme-dark-content: #D6DADA;
$theme-dark-border: #252525;
$theme-dark-pane1-background: #d89d2a;
$theme-dark-pane2-background: #617d98;
$theme-dark-header-background: #000;

.theme-dark {
  .ant-modal-content {
    background-color: $theme-dark-background;
    border-color: $theme-dark-border;

    .ant-modal-header {
      background-color: $theme-dark-background;
      border-color: $theme-dark-border;

      .ant-modal-title {
        color: $theme-dark-content;
      }
    }

    .ant-modal-footer {
      background-color: $theme-dark-background;
      border-color: $theme-dark-border;

    }

    .ant-modal-close {
      color: $theme-dark-content;
    }
    .ant-modal-body {
      span {
        color: $theme-dark-content;
      }

      input {
        background-color: $theme-dark-background;
        color: $theme-dark-content;
      }
    }
  }
}

