.library-panel {
  width: 100%;
  height: 100%;
  padding: 10px 30px;

  .panel-title-container {
    display: flex;
  }

  .muuri {
    width: 100%;
    height: 100%;
    min-height: 160px;
  }

  .context-menu-container {
    position: fixed;
    list-style: none;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 9px 1px #717171;
    background: #fff;
    z-index: 100;

    li {
      cursor: pointer;
      padding: 5px 10px;
      color: #3c3b3b;
      border-radius: 4px;

      &:hover {
        background: #1890ff;
        color: #fff;
      }
    }
  }

  .empty-item-container {
    width: 130px;
    height: 130px;
    border: 2px dashed white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;

    span {
      font-size: 36px;
      color: #fff;
    }
  }
}