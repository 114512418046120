@import '../../theme';
$small: 768px;
.header-container {
  height: 60px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-family:'Sanspro';

  a {
    color: #000;
    font-weight:bold;
    font-size:0.9rem;
  }

  .headerptitle{
    margin-left:1rem;
    font-family:'Sanspro';
  }

  @media screen and (max-width: $small) {
    .headerptitle{
      display:block;
    }
  }

  .title {
    font-size: 20px;
  }

  .action-container {
    position: absolute;
    right: 85px;
    margin: 10px;
    top: 0;

    @media (max-width: 768px) {
      right: -8px;
    }
  }

  .avatar-container {
    .avatar-wrapper-mobile {
      font-size: 18px;

      @media (min-width: 767px) {
        display: none;
      }
    }
  }
}

.header-container.theme-light {
  border-color: $theme-light-border;
  background-color: $theme-light-background;
  background-color: $theme-light-header-background;

  a {
    color: $theme-light-content;
  }

  span {
    color: $theme-light-content;
  }
}

.header-container.theme-dark {
  border-color: $theme-dark-border;
  background-color: $theme-dark-header-background;

  a {
    color: $theme-dark-content;
  }

  span {
    color: $theme-dark-content;
  }
}
