.avatar-wrapper {
  background-position: center;
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }
}

.avatar-wrapper-text {
  background-position: center;
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;

  @media (max-width: 768px) {
    display: none;
  }
}

.compose-panel .compose-panel-viewport .muuri{
  width:auto;
}
