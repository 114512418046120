@import '../../theme';

.compose-page {
  height:100%
}

.flexlayout__splitter.flexlayout__splitter_horz,.flexlayout__splitter_drag.flexlayout__splitter_horz{
  height:30px !important;
}

.theme-dark{
  .design-panel-wrapper{
    background-color:#434343 !important;
  }

  .flexlayout__splitter_drag{
    background-color:#000;
  }

  .design-panel-wrapper{
    .title-container{
      span{
        color:#D6DADA;
        opacity:1;
      }
    }

    .title-container{
      justify-content:space-between;
    }
  }

  .compose-item{
    .item-content{
     // background-color:#000 !important;
    }

    .item-content{
      .item-title{
        color:#000;
      }

      .action-bar-container{
        color:#D6DADA;
      }
    }

    .item-content{
      .action-container{
        background:#27292e;
        border: 2px solid #888888;
      }
    }
  }

  .compose-panel{
    .chip-card.selected{
      border:2px dotted #fff !important;
    }

    .panel-sub-title{
      b{
        color:#D6DADA;
      }
    }

    .searchinput{
      color:#D6DADA;
    }

    .searchinput{
      input{
        border-bottom: 1px solid #D6DADA;
      }
    }
  }

  
}

.theme-light{
  .compose-page {
    .panel-title-container,.compose-panel{
      background-color:#D6D6D6;

    }

    .compose-panel-viewport{
      background-color:#D6D6D6;
    }

    .searchinput{
      font-weight:bold;
      color:#000;
    }
  }



  .flexlayout__tab > div{
    background-color:#F7F7F7;
  }

  .compose-panel{
    padding:0
  }


}

.compose-page {
  .compose-page-wrapper {
    .Resizer {
      background: #fff;
      z-index: 0;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -moz-background-clip: padding;
      -webkit-background-clip: padding;
      background-clip: padding-box;
    }

    .Resizer:hover {
      -webkit-transition: all 2s ease;
      transition: all 2s ease;
    }

    .Resizer.horizontal {
      height: 11px;
      margin: -5px 0;
      border-top: 5px solid #fff;
      border-bottom: 5px solid #fff;
      cursor: row-resize;
      width: 100%;
    }

    .Resizer.horizontal:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.vertical {
      width: 11px;
      margin: 0 -5px;
      border-left: 5px solid rgba(255, 255, 255, 0);
      border-right: 5px solid rgba(255, 255, 255, 0);
      cursor: col-resize;
    }

    .Resizer.vertical:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
    .Resizer.disabled {
      cursor: not-allowed;
    }
    .Resizer.disabled:hover {
      border-color: transparent;
    }

    .Pane.horizontal.Pane1 {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .Pane.horizontal.Pane2 {
      overflow-x: auto;
      overflow-y: auto;
    }

    .SplitPane {
      background-color: #d2d2d4;
    }
  }
}

.empty-project-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .anticon.anticon-inbox {
    font-size: 100px;
    color: #e4e4e4;
  }

  .label {
    color: #696969;
  }
}

.compose-page.theme-dark {
  .Pane1 {
    background-color: $theme-dark-pane1-background;
  }

  .Pane2 {
    background-color: $theme-dark-pane2-background;
  }

  .Resizer {
    border-color: $theme-dark-border !important;
    background-color: $theme-dark-border !important;
  }
}

.compose-page.theme-light {
  .Pane1 {
    background-color: $theme-light-pane1-background;
  }

  .Pane2 {
    background-color: $theme-light-pane2-background;
  }

  .Resizer {
    border-color: $theme-light-border !important;
    background-color: $theme-light-border !important;
  }
}

.searchinput input,.searchinput input:focus-visible{
  border: none;
    background: transparent;
    border-bottom: 1px solid #000;
    
    padding-left:5px;
}

.searchinput input:focus{
    outline: none;
}

.compose-panel .panel-title-container .panel-sub-title{
  position:relative;
}

.panel-title-container{
  display: flex;
    justify-content: space-between;
  padding:0.8rem;
}

.searchinput{
  font-weight:bold;
}

.compose-panel{
  .compose-panel-viewport{
    min-height:330px;
  }
}