.compose-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 // padding: 10px 30px;
  min-width: 100%;
  position: absolute;

  .panel-title-container {
    position: relative;
    width: 100%;
    .panel-title {
      font-size: 16px;
      color: #000;
      font-family: 'Inter', serif;
      font-weight: 700;
    }
    .panel-sub-title {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .context-menu-container {
    position: fixed;
    list-style: none;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 9px 1px #717171;
    background: #fff;
    z-index: 1000;

    li {
      cursor: pointer;
      padding: 5px 10px;
      color: #3c3b3b;
      border-radius: 4px;

      &:hover {
        background: #1890ff;
        color: #fff;
      }
    }
  }

  .compose-panel-viewport {
    width: 100%;

    .muuri {
      margin-left: 144px;
      width: 100%;
      min-height: 150px;
    }
  }

  .empty-item-container {
    position: absolute;
    top: 4rem;
    left: 0.8rem;
    width: 130px;
    height: 130px;
    border: 2px dashed white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 36px;
      color: #fff;
    }
  }
}
