.color-picker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .color-picker-item {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin: 4px 12px;
    cursor: pointer;
    border: 2px solid #cccccc;
  }
}
