.design-panel {
  .title-container {
    padding-top: 10px
  }

  .title-container {
    .panel-title {
      width: 33%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .searchinput {
    display: flex;
  }

  .searchinput input:focus {
    box-shadow: none;
  }

  .searchinput {
    input {
      line-height: normal;
    }
  }
}

.design-panel-wrapper {
  padding-bottom: 15px;
}

.theme-dark {
  .flexlayout__splitter.flexlayout__splitter_horz {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 80' width='40' height='15'><rect width='100' height='8' style='fill:rgb(255,255,255);stroke-width:0;stroke:rgb(255,255,255)'></rect><rect style='fill:rgb(255,255,255);stroke-width:0;stroke:rgb(255,255,255)' y='20' width='100' height='8'></rect><rect style='fill:rgb(255,255,255);stroke-width:0;stroke:rgb(255,255,255)' y='40' width='100' height='8'></rect></svg>") !important;
    width: 100px;
    height: 15px !important;
    display: inline-block;
    background-repeat: no-repeat !important;
    background-position: center 3px !important;
    padding-top: 0px !important;
    top: 0px;
  }

  .design-panel {
    .searchinput {
      input {
        border-bottom: 1px solid #D6DADA;
        color: #D6DADA;
      }
    }

    .content-header,
    .content-action-wrapper {
      background-color: #303030 !important;
    }

    .card-content {
      .card-content-wrapper {
        background-color: #202226;
        color: #fff;
      }
    }

    .card-content {
      background-color: #202226 !important;
    }
  }
}

.theme-light {
  .flexlayout__splitter.flexlayout__splitter_horz {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 80' width='40' height='15'><rect width='100' height='8' style='fill:rgb(0,0,0);stroke-width:0;stroke:rgb(0,0,0)'></rect><rect style='fill:rgb(0,0,0);stroke-width:0;stroke:rgb(0,0,0)' y='20' width='100' height='8'></rect><rect style='fill:rgb(0,0,0);stroke-width:0;stroke:rgb(0,0,0)' y='40' width='100' height='8'></rect></svg>") !important;
    width: 100px;
    height: 15px !important;
    display: inline-block;
    background-repeat: no-repeat !important;
    background-position: center 3px !important;
    padding-top: 0px !important;
    top: 0px;
    background: #ebeaea;
  }

  .design-panel {
    .searchinput {
      input {
        color: #000;
        opacity: 1;
      }
    }

    .title-container {
      justify-content: space-between;
    }

    .title-container {
      .panel-title {
        color: #000;
        opacity: 1;
      }
    }
  }

  .searchinput {
    color: #000;
    opacity: 1;
  }
}

.design-panel-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .action-btn-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;

    .action-btn-wrapper {
      position: fixed;
      margin-top: -28px;

      span {
        color: gray;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }
}

.empty-project-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .anticon.anticon-inbox {
    font-size: 100px;
    color: #e4e4e4;
  }

  .label {
    color: #696969;
  }
}

.custom-css-class {
  height: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
}