.chip-card {
  cursor: pointer;
  height: 130px;
  margin: 10px;
  position: absolute;
  width: 130px;
  box-shadow: 0 4px 20px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 3px solid #fff;

  .item-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //background: #fff;
    border-radius: 5px;

    .item-title {
      color: #000;
      text-align: center;
    }

    .action-container {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      background: #888888;
      border-radius: 10px;
      box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .action-bar-container {
      position: absolute;
      bottom: -27px;
    }
  }

  &.selected {
    border: 3px dashed #444444;
  }
}
