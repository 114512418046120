.RichTextEditor__root___2QXK-{
  height:100%;
  background:transparent !important;
  border:none !important;
}



.RichTextEditor__editor___1QqIU{
  height:calc(100% - 60px);
}

.DraftEditor-root{
  height:100% !important;
}

.color-picker-wrapper{
  z-index:9999;
}

@media only screen and (min-width: 800px) {
  .full-screen-mode{
    position:fixed !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    top:0;
    height:98% !important;
    width:90% !important;
    z-index:9999;
  }
}

.design-card {
  cursor: pointer;
  margin: 10px;
  position: absolute;
  box-shadow: 0 4px 20px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  .muuri-item {
    height: 100%;
    position: relative;

    .item-handler {
      position: absolute;
      right: 10px;
      top: 5px;

      span {
        font-size: 17px;
        color: #424242;
      }
    }

    .card-content {
      height: 100%;
      border-radius: 5px;
      position: relative;
      overflow-x: hidden;
      overflow-y: hidden;

      img{
        max-width:100%;
      }

      &.selected {
        border: 1px solid #888888;
      }

      .content-header {
        height: 30px;
        cursor: pointer;
        background-color: green;
        align-items: center;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;

        .title-text {
          color: #fff;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-align: left;
        }

        .title-input {
          color: #fff;
          background-color: transparent;
          border: none;
          outline: none;
          width: 100%;
        }

        .card-action-container {
          cursor: pointer;
          display: flex;
          align-items: center;

          span {
            font-size: 17px;
            color: #424242;
          }
        }
      }

      .card-content-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 0 5px;

        .item-image-wrapper {
          width: 100%;

          .item-image {
            width: 100%;
          }
        }

        .content-wrapper {
          height: calc(100% - 60px);

          .card-input {
            border: none;
            font-size: 16px;
            outline: none;
            width: 100%;
            background-color: transparent;
            height: 100%;
          }

          .data-preview {
            text-align: left;
          }
        }

        .empty-content-wrapper {
          position: absolute;
          top: 30px;
          color: gray;
        }
      }

      .edit-mode {
        height: calc(100% - 40px);
      }

      .content-action-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #888888;

        .btn-group {
          display: flex;

          .color-action {
            position: relative;

            &:hover {
              .color-picker-wrapper {
                display: block;
              }
            }

            .color-picker-wrapper {
              display: none;
              position: absolute;
              width: 176px;
              top: -95px;
              background: #fff;
              border-radius: 10px;

              &:hover {
                display: block;
              }
            }
          }

          .size-action {
            position: relative;
            font-size: 20px;
            width: 30px;

            &:hover {
              .size-picker-wrapper {
                display: block;
              }
            }

            .size-picker-wrapper {
              display: none;
              position: absolute;
              width: 120px;
              top: -58px;
              background: #fff;
              border-radius: 10px;
              box-shadow: 0 4px 20px 10px rgba(0, 0, 0, 0.2);

              &:hover {
                display: block;
              }
            }
          }

          .image-ation {
            position: relative;
            cursor: pointer;

            .anticon-file-image {
              cursor: pointer;
            }

            .image-uploader {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              opacity: 0;
              width: 100%;
              cursor: pointer;
            }
          }

          .archive-action {
            z-index: 1000;
          }
        }
        span {
          font-size: 20px;
          color: #aaaaaa;
          cursor: pointer;
          padding: 5px;
          margin: 0 10px;

          &:first-child {
            margin: 0 10px 0 0;
          }

          &:hover {
            color: #333333;
          }
        }
      }
    }
  }
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 5px 5px 0;
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
