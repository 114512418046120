@import '../../theme';

.empty-panel-container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &.theme-light {
    background-color: $theme-light-background;

    h1 {
      color: $theme-light-content;
    }
  }

  &.theme-dark {
    background-color: $theme-dark-background;

    h1 {
      color: $theme-dark-content;
    }
  }
}
