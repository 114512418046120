@import "../../theme";

.theme-light .flexlayout__tab .main-menu{
        background-color:#fff !important;
      }

.main-menu {
  .btn-group {
    margin: 20px 10px;

    button {
      width: 140px;
      margin: 10px 0;
    }
  }

  .project-tree-container {
    text-align: left;

    &.theme-dark {
      .ant-tree {
        background: $theme-dark-background;
        color: $theme-dark-content;
      }
      .ant-tree-switcher {
        background: $theme-dark-background;
        color: $theme-dark-content;
      }
    }

    &.theme-light {
      .ant-tree {
        background: $theme-light-background;
        color: $theme-light-content;
      }
      .ant-tree-switcher {
        background: $theme-light-background;
        color: $theme-light-content;
      }


    }
  }

  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.template-wrapper {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;

  .template-item {
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 6px 1px #6f6f6f;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;

    .template-title {
    }

    &.selected {
      box-shadow: 1px 1px 10px 5px #6f6f6f;
    }
  }
}

.ant-modal-body {
  .title-container {

    .error-msg {
      color: #ff0000;
    }
  }

  .error-msg {
    color: #ff0000;
  }
}
