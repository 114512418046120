@import "../../theme";

.theme-light{
  .flexlayout__tab_border_right{
    background-color:#fbfaf0 !important;
  }

  .setting-panel{
    background-color:#fbfaf0 !important;
  }

  .title-container{
    span{
      color:#3c3c3c;
    }

    .header-title{
      color:#3c3c3c !important;
    }
  }

  .content-container{
    .title-wrapper{
      span{
        color:#3c3c3c;
      }

      input{
        color:#3c3c3c !important;
      }
    }

    .content-wrapper{
      textarea{
        color:#3c3c3c !important;
      }
    }
  }
}

.theme-dark{
  .flexlayout__tab_border_right{
    background-color:#202020 !important;
  }

  .setting-panel{
    background-color:#202020 !important;
  }

  .title-container{
    span{
      color:#cacaca;
    }

    .header-title{
      color:#cacaca !important;
    }
  }

  .content-container{
    .title-wrapper{
      span{
        color:#cacaca;
      }

      input{
        color:#cacaca !important;
      }
    }

    .content-wrapper{
      textarea{
        color:#cacaca !important;
      }
    }
  }
}

.setting-panel {
  .chiptitle{
    color: #fff;
    font-weight: bold;
    background-color: #000;
    border: 1px dotted #fff;
    display: inline-block;
    padding: 0.5rem;
    padding-top: 0.2rem;
    margin-bottom: 20px;
    padding-bottom: 0.2rem;
  }

  .tabbar .ant-tabs-nav{
    display:none;
  }

  .data-area-container{
    padding-top:0;
  }

  .flexlayout__tab_border_right{
    background-color:#212E40;
  }

  .data-area-container .data-item-container{
    padding:0;
    margin:0;
    background:none;
  }

  .content-container{
    padding:0;
  }

  h1 {
    text-align: left;
  }

  text-align: left;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  &.theme-dark {
    h1 {
      color: $theme-dark-content;
    }

    .data-area {
      .data-preview {
        color: $theme-dark-content;
        border-color: $theme-dark-border;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $theme-dark-content;
      }
    }
  }

  &.theme-light {
    h1 {
      color: $theme-light-content;
    }

    .data-area {
      .data-preview {
        color: $theme-light-content;
        border-color: $theme-light-border;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $theme-light-content;
      }
    }
  }
}

.setting-panel{
 // background-color:#212E40;
}

.title-container .header-title{
  font-size:15px !important;
  font-weight:bold;
  color:#fff !important;
  opacity: 0.6;
  margin-left:5px;
}

.content-container .title-wrapper input,.content-container .content-wrapper textarea{
  font-size:14px !important;
  color:#fff !important;
  opacity: 0.6;
}

.title-container{
  margin-bottom:15px;
}

.title-wrapper{
  margin-bottom:10px;
}

.empty-panel-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.theme-dark {
    h2 {
      color: $theme-dark-content;
    }
  }

  &.theme-light {
    h2 {
      color: $theme-light-content;
    }
  }
}
